* {
  font-family: 'Roboto Condensed'!important;
}

body {
  height: 100%;
  font-family: 'Roboto Condensed';
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(149, 160, 237, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.container {
  display: flex;
  justify-content: flex-end;
}
.container-center {
  width: 85%;
  margin: auto;
}
.table-button-font-Size {
  font-size: '18px';
}
.fixed-header {
  height: 90px; 
  background-color: #02846d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.content-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 100px; /* Ensure content starts below the fixed header */
}
.layout-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(../img/background-img.jpg) no-repeat center center; 
  background-size: cover;
}
.fixed-footer {
  text-align: center;
  padding-top: 0px;
  background: none;
}
.header-app-links .ant-space-item {
  width: 90px;
}
.header-app-links .ant-space-item a {
  padding: 5px;
  color: #f8f8f8;
}
.header-app-links .ant-space-item span.header-link-text {
  font-size: 16px;
  padding-left: 5px;
}
.header-app-links .ant-space-item a:hover {
  color: #f8f8f8;
  background-color: #ebcb39;
  border-radius: 10px;
}
.header-app-links .ant-space-item a:active {
  color: #ebcb39;
  background-color: #f8f8f8;
}
#footer-app-links .ant-space-item {
  font-size: 20px;
  color: #02846d;
  border: 2px solid #02846d;
  padding: 5px;
  border-radius: 10px;
  width: 160px;
}
#footer-app-links .ant-space-item a {
  color: #02846d;
}
#footer-app-links .ant-space-item span.footer-link-text {
  font-size: 16px;
}
#footer-app-links .ant-space-item:hover {
  background-color: #02846d;
}
#footer-app-links .ant-space-item:hover a {
  color: #f8f8f8;
}
#footer-app-links .ant-space-item:active {
  background: none;
}
#footer-app-links .ant-space-item:active a {
  color: #02846d;
}
.form-login-page {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -180px 0 0 -420px;
  width: 840px;
  height: 420px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.form-login-left {
  padding-top: 5%;
  align-items: center;
  background-color: #02846d;
  text-align: center;
  height: 420px;
  border-radius: 15px 0px 0px 15px;
}
.login-form {
  width: 80%;
  padding: 24px 0 0 100px;
}
.signup-form {
  width: 80%;
  padding: 24px 0 0 100px;
}
.signup-form .ant-form-item {
  margin-bottom: 8px;
}
.form-login-right {
  background-color: #ffffff;
  height: 420px;
  border-radius: 0px 15px 15px 0px;
}
.forgot-password{
  margin-bottom: 25px;
}
#form-login-logo {
  width: 120px;
  padding-top: 14px;
}
#login-footer-app-links {
  text-align: center;
}
#login-footer-app-links .ant-space-item {
  width: 100px;
  font-size: 26px;
  color: #02846d;
  border: 2px solid #02846d;
  border-radius: 10px;
  padding-top: 5px;
}
#login-footer-app-links a {
  color: #02846d;
  display: inline-flex;
}
#login-footer-app-links a span.link-title {
  font-size: 12px;
  padding-left: 5px;
}
#login-footer-app-links .ant-space-item:hover {
  background-color: #02846d;
}
#login-footer-app-links .ant-space-item:hover a {
  color: #f8f8f8;
}
#login-footer-app-links .ant-space-item:active {
  background-color: #f8f8f8;
}
#login-footer-app-links .ant-space-item:active a {
  color: #02846d;
}
#dashboard-logo {
  position: relative;
  width: 110px;
}
#user {
  height: 100%;
  display: flex;
  align-items: center;
  color: aliceblue;
  font-weight: bold;
  font-size: 36px;
  font-family: 'Roboto Condensed';
  text-shadow: 0px 0px 10px black;
}
#controls-bar {
  padding-bottom: 5px;
  margin: 15px;
}
#copyright {
  margin-top: 40px;
  font-family: 'Roboto Condensed';
}
.font-family-roboto {
  font-family: 'Roboto Condensed';
}
.font-size-bold-42 {
  font-weight: bold;
  font-size: 42px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
#sign-up {
  text-decoration-line: none;
}
#sign-up:hover {
  color: black;
}
#signin-header {
  font-weight: bold;
  font-size: 32px;
  font-family: 'Roboto Condensed';
  text-align: center;
  color: #02846d;
}
.order-color {
  color: #02846d;
}
#signin-btn, #signup-btn {
  background-color: #02846d;
  width: 100px;
}
#signin-btn:hover, #signup-btn:hover {
  background-color: #02BF9B;
}
#signin-btn:active, #signup-btn:active {
  background-color: #02846d;
}
.spinner-loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
#login-left-note {
  padding: 12px;
  color: white;
  font-family: 'Roboto Condensed';
  font-size: 16px;
}
#update-browser {
  font-family: 'Roboto Condensed';
  font-size: 16px;
}
.content-flex-end {
  display: flex;
  justify-content: flex-end;
}
.width-100 {
  width: 100%;
}
.float-btn {
  right: 50px;
  background: #02846d;
  opacity: 0.3;
  transition: all 0.3s;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);
}
.float-btn:hover {
  opacity: 1;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: right;
  background-color: #02846d;
  color: #f8f8f8;
  font-weight: bold;
}
.ant-table-wrapper .ant-table-column-sorters {
  background-color: #02846d;
}
.ant-table-wrapper .ant-table-thead > tr th:first-child {
  text-align: left;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  color: white;
  font-size: 16px;
}
.ant-table-column-sorters::after {
  background-color: #02846d;
}
.footer-divider {
  margin: 0px;
  margin-top: 10px;
}
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
  border-start-end-radius: 0px;
}